<template>
  <!-- <v-dialog v-model="dialog" max-width="800px" persistent> -->
  <base-form-layout
    :title="`${action} ${$tc('workOrder.name', 1)}`"
    @submit="onSubmit"
  >
    <v-form ref="form">
      <TaskGroupEditForm :task-group="taskGroup" class="mt-5" />
    </v-form>
  </base-form-layout>
  <!-- </v-dialog> -->
</template>

<script>
import TaskGroupEditForm from './components/TaskGroupEditForm'
import { TASK_GROUP } from '@/models/task.group'
import { mapActions } from 'vuex'
export default {
  components: {
    TaskGroupEditForm
  },
  props: {
    taskGroupId: {
      type: String,
      default: ''
    },
    orderId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      taskGroup: TASK_GROUP(this.orderId),
      dialog: false
    }
  },
  computed: {
    action() {
      return this.$t(this.taskGroupId ? 'actions.edit' : 'actions.create')
    }
  },
  watch: {
    $route: {
      immediate: true,
      async handler(value) {
        this.dialog = value.meta.dialog
        if (this.taskGroupId)
          this.taskGroup = await this.fetchTaskGroup(this.taskGroupId)
      }
    }
  },

  methods: {
    ...mapActions({
      addNotification: 'notification/add'
    }),
    async fetchTaskGroup(id) {
      const response = await this.$store.dispatch('taskGroup/fetch', id)
      return response.data
    },
    async onSubmit() {
      try {
        const action = this.taskGroupId ? 'update' : 'create'
        const response = await this.$store.dispatch(
          `taskGroup/${action}`,
          this.taskGroup
        )
        this.$router.replace({
          name: 'task-group-show',
          params: { taskGroupId: response.data.id }
        })
        this.addNotification({
          status: '200',
          color: 'success',
          time: 2000,
          messages: { title: `Successfully ${action}d` }
        })
      } catch (error) {
        if (!error.response) return
        const {
          status,
          data: { errors }
        } = error.response
        return this.addNotification({
          status,
          messages: errors,
          color: 'error',
          time: 10000
        })
      }
    }
  }
}
</script>
