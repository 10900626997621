import { ORGANIZATION_ID } from '@/common/config.js'

export const TASK_GROUP = outgoingShipping => ({
  type: 'workTaskGroup',
  attributes: {
    groupType: 'release',
    title: '',
    details: null,
    deliveredTo: {
      companyName: '',
      location: {},
      estimatedUnloadDateTo: null
    },
    outgoingShipping,
    purchaseOrders: [],
    organizationId: ORGANIZATION_ID()
  }
})
