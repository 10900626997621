<template>
  <v-row dense
    ><v-col cols="12" md="4">
      <base-field-input
        v-model="taskGroup.attributes.title"
        :label="$tc('workOrder.name', 1) + '#'"
    /></v-col>
    <v-col>
      <base-selector
        v-model="taskGroup.attributes.outgoingShipping"
        :disabled="!!taskGroup.id"
        :label="$tc('sale.name', 2)"
        :items="saleOrders"
        item-text="attributes.customAttributes.name"
        item-value="id"
        @input="updateDestination"
      />
    </v-col>
    <v-col cols="12">
      <base-textarea-input
        v-model="taskGroup.attributes.details"
        :label="$t('common.details')"
    /></v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    taskGroup: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      saleOrders: []
      // purchaseOrders: []
    }
  },

  async created() {
    this.saleOrders = await this.filterOrders({ subCategory: 'sale' })
    // this.purchaseOrders = await this.filterOrders({ subCategory: 'purchase' })
  },
  // API CALLS
  methods: {
    async filterOrders() {
      return await this.$store.dispatch('saleOrder/filter')
    },
    async updateDestination(orderId) {
      const destination = await this.getLoadDestination(orderId)
      const party = await this.getClient(orderId)
      const { location, estimatedUnloadDateTo } = destination.attributes
      const { companyName } = party.attributes

      this.taskGroup.attributes.deliveredTo = {
        location,
        companyName,
        estimatedUnloadDateTo
      }
    },
    async getLoadDestination(orderIds) {
      const params = {
        orderIds,
        category: 'Destination'
      }
      const response = await this.$store.dispatch('load/filter', params)
      return response[0]
    },
    async getClient(orderIds) {
      const response = await this.$store.dispatch('party/filter', { orderIds })
      return response[0]
    }
  }
}
</script>

<style lang="scss" scoped></style>
